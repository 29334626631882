<template>
    <v-card
        class="pb-2 -mt-8 md:pt-3 pt-6"
        :class="props.class"
        outlined
    >
      <v-row>
        <v-col
            class="py-0 md:py-4"
            :cols="12"
            md="7"
        >
          <v-card-text class="pb-0 md:pb-4">
            <p class="headline-3">Dlaczego ta oferta jest na pierwszym miejscu?</p>
            Ofertę wybraliśmy korzystając z narzędzia, którym na codzień posługuje się ponad 1600 ekspertów finansowych.
          </v-card-text>
        </v-col>
        <v-col cols="12" md="5" class="items-center flex md:pr-8 mb-2 md:mb-0 mt-2">
          <v-row>
            <v-col v-for="badge in badges" :key="badge.name" class="text-center">
              <div class="mx-auto">
                <v-icon color="lendi" size="large">
                  {{ badge.value }}
                </v-icon>
              </div>
              <span class="text-lendi headline-5">{{ badge.name }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
</template>

<script lang="ts" setup>
import {mdiThumbUpOutline, mdiPiggyBankOutline, mdiUpdate} from '@mdi/js';
import type {LDictionary} from '~/types/LDictionary';
import LContrastThemeProvider from "~/components/LContrastThemeProvider.vue";

const props = defineProps({
  class: {
    type: String,
  },
});
const badges: LDictionary = [
  {
    name: 'Najtańsza',
    value: mdiPiggyBankOutline,
  },
  {
    name: 'Aktualna',
    value: mdiUpdate,
  },
  {
    name: 'Dostępna',
    value: mdiThumbUpOutline,
  },
];

</script>

<style scoped lang="scss">

</style>
